export class HttpError extends Error {
  // @ts-ignore
  private status: number;
  // @ts-ignore
  private context?: any;

  constructor(status: number, message: string, context?: any) {
    super(message);
    this.status = status;
    this.context = context;
  }
}
