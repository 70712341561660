import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { ANNOTATION_LOCATION, ANNOTATION_ORIGIN_LOCATION } from '@backstage/catalog-model';
import { AddClusterPayload, ResourceK8SClusterConfig } from '@tmatic/configurator-common';
import { ResourceFactory, ClusterResourceType, Resource } from '@tmatic/entity-management-common';
import { TMATIC_ANNOTATION_RESOURCE_CLUSTER_CONFIG_PATH, TMATIC_ANNOTATION_RESOURCE_CLUSTER_GROUP, TMATIC_ANNOTATION_RESOURCE_CLUSTER_URL } from '@tmatic/constants-common';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CreateClusterData } from '../components/modals/CreateClusterModal';
import { normalizeEntityRef } from '@tmatic/utils';
import {entityManagementApiRef} from "../apis";

type AddClusterMode = 0 | 1 | 2; // 0 - hidden | 1 - add existing | 2 - create new

const getLink = (entity: Resource) => `/catalog/${entity.metadata.namespace || 'default'}/${entity.kind.toLocaleLowerCase('en-US')}/${entity.metadata.name}`;

export const useAddK8SCluster = () => {
  const [showModal, setShowModal] = useState<AddClusterMode>(0);
  const catalogApi = useApi(catalogApiRef);
  const entityManagerClient = useApi(entityManagementApiRef);
  
  
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();

  const ensureEntityAbsence = async (name: string) => {
    const entity = await catalogApi.getEntityByRef({ name, namespace: 'default', kind: 'resource' }).catch(() => undefined);
    if (entity) {
      throw new Error(`Resource with name "${name}" already exists`);
    }
  };

  const onAddSubmit = async ({ clusterData: data, owner, description, system }: AddClusterPayload<ResourceK8SClusterConfig>, type: ClusterResourceType) => {
    try {
      await ensureEntityAbsence(data.name);

      const response = await entityManagerClient.request({
        url: 'configurator/cluster-config',
        method: 'POST',
        body: JSON.stringify(data),
      });

      const resource = await entityManagerClient.request({
        url: 'entity-management/entities',
        method: 'POST',
        body: JSON.stringify(
          ResourceFactory.createFromPayload({
            name: data.name,
            description,
            type,
            owner: normalizeEntityRef(owner, { defaultKind: 'group' }),
            system: normalizeEntityRef(system, { defaultKind: 'system' }),
            annotations: {
              [ANNOTATION_LOCATION]: 'immediate:immediate',
              [ANNOTATION_ORIGIN_LOCATION]: 'immediate:immediate',
              [TMATIC_ANNOTATION_RESOURCE_CLUSTER_GROUP]: 'external',
              [TMATIC_ANNOTATION_RESOURCE_CLUSTER_CONFIG_PATH]: response.configPath,
              [TMATIC_ANNOTATION_RESOURCE_CLUSTER_URL]: data.server,
            },
          }),
        )
      });

      navigate(getLink(resource));
    } catch (e) {
      errorApi.post(e);
    }
  };

  const onCreateNewSubmit = async ({ clusterData: data, system, owner, description }: AddClusterPayload<CreateClusterData>, type: ClusterResourceType) => {
    try {
      await ensureEntityAbsence(data.name);
      
      const resource = await entityManagerClient.request({
        url: 'clusters',
        pluginId: 'resources-installer',
        method: 'POST',
        body: JSON.stringify({
          name: data.name,
          type,
          owner: normalizeEntityRef(owner, { defaultKind: 'group' }),
          system: normalizeEntityRef(system, { defaultKind: 'system' }),
          description,
        })
      });

      navigate(getLink(resource));
    } catch (e) {
      errorApi.post(e);
    }
  };

  const onSubmit = async (data: AddClusterPayload<ResourceK8SClusterConfig | CreateClusterData>, type: ClusterResourceType) => {
    switch (showModal) {
      case 1:
        return onAddSubmit(data as AddClusterPayload<ResourceK8SClusterConfig>, type);
      case 2:
        return onCreateNewSubmit(data, type);
      default:
        return undefined;
    }
  };

  return {
    showModal,
    setShowModal,
    submitFor: (type: ClusterResourceType) => (data: AddClusterPayload<ResourceK8SClusterConfig | CreateClusterData>) => onSubmit(data, type),
  };
};
