import { Progress, Select, SelectItem } from '@backstage/core-components';
import { parseEntityRef } from '@backstage/catalog-model';
import { RunApplicationPayload } from '@tmatic/plugin-argocd-common';
import { validate } from '@tmatic/configurator-common';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import _ from 'lodash';
import { AsyncState } from 'react-use/lib/useAsync';
import { ErrorObject } from 'ajv/lib/types';
import { CustomFormLabel } from '../../../CustomFormLabel';
import { FormErrorMessage } from '../../../FormErrorMessage';
import { RepoUrlPickerDropdown } from './RepoUrlPickerDropdown';

export interface ArgoCDRepositoryRecord {
  repo: string;
  type: 'git';
}

export const useStyles = makeStyles({ fullWidthInput: { width: '100%' } });
const UI_SCHEMA = Object.freeze({
  'ui:options': {
    requestUserCredentials: {
      secretsKey: 'USER_OAUTH_TOKEN',
      additionalScopes: {
        github: ['read:org', 'repo', 'repo:status', 'user:email'],
      },
    },
    allowedHosts: ['github.com'],
  },
});

export interface CreateApplicationFormProps {
  setForm: (form: any) => void;
  formData: Record<string, any>;
  artifacts: AsyncState<SelectItem[]>;
  templates: AsyncState<SelectItem[]>;
  instances: AsyncState<SelectItem[]>;
  projects: AsyncState<SelectItem[]>;
  clusters: AsyncState<SelectItem[]>;
  groups: AsyncState<SelectItem[]>;
  systems: AsyncState<SelectItem[]>;
  repositories: AsyncState<SelectItem[]>;
  namespaceSettings: AsyncState<{ strategy: '*' }> | AsyncState<{ strategy: 'list'; records: string[] }>;
  errors: ErrorObject[] | null;
}

const isInputDisabled = (formData: Record<string, any>, prop: string): boolean => {
  const { values } = formData;
  switch (prop) {
    case 'argoCDProject':
    case 'clusterName':
    case 'repoUrl': {
      return !values.argoCDInstance;
    }
    case 'namespace': {
      return !values.argoCDInstance || !values.argoCDProject || !values.clusterName;
    }
    default: {
      return false;
    }
  }
};

const getLabelForSelect = (text: string) => (<CustomFormLabel pull="right" text={text} />) as unknown as string;

const getLabelForText = (text: string) => <CustomFormLabel text={text} />;

export const CreateApplicationForm = (props: CreateApplicationFormProps) => {
  const classes = useStyles();
  const wasChanged = useMemo(() => new Set<string>(), []);

  const { artifacts, templates, instances, projects, clusters, namespaceSettings, setForm, formData, systems, groups, repositories } = props;

  const createOnChangeHandler = (placeholder: string) => {
    return (value: any) => {
      wasChanged.add(placeholder);
      const newFormState = _.clone(formData);
      _.set(newFormState, placeholder, value);
      setForm(newFormState);
    };
  };
  const setDeploymentName = createOnChangeHandler('values.deploymentName');
  const setDescription = createOnChangeHandler('values.description');
  const setOwner = createOnChangeHandler('values.owner');
  const setSystem = createOnChangeHandler('values.system');

  const setArtifactName = createOnChangeHandler('values.artifactName');
  const setNamespace = createOnChangeHandler('values.namespace');
  const setArgoCDProject = createOnChangeHandler('values.argoCDProject');
  const setArgoCDInstance = createOnChangeHandler('values.argoCDInstance');
  const setRepoUrl = createOnChangeHandler('values.repoUrl');

  const errors = validate(RunApplicationPayload, formData);
  const renderNSInput = () => {
    const label = 'Namespace *';
    if (namespaceSettings.value?.strategy === '*') {
      return (
        <TextField
          className={classes.fullWidthInput}
          label={getLabelForText(label)}
          placeholder="Type namespace name to deploy application"
          onChange={event => setNamespace(event.target.value)}
          value={formData.values.namespace}
          disabled={isInputDisabled(formData, 'namespace')}
        />
      );
    }
    return (
      <Select
        label={getLabelForSelect(label)}
        items={_.map(namespaceSettings.value?.records, value => ({ label: value, value }))}
        onChange={setNamespace}
        selected={formData.values.namespace}
        disabled={isInputDisabled(formData, 'namespace')}
      />
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">Metadata</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} marginTop={4}>
            <TextField
              className={classes.fullWidthInput}
              label={getLabelForText('Deployment Name *')}
              placeholder="Deployment Name"
              onChange={event => setDeploymentName(event.target.value)}
              value={formData.values.deploymentName}
              disabled={isInputDisabled(formData, 'deploymentName')}
            />
            <FormErrorMessage errors={errors} wasChanged={wasChanged} property="deploymentName" prefix="values" />
          </Grid>
          <Grid item xs={12} marginTop={2}>
            <TextField
              className={classes.fullWidthInput}
              label={getLabelForText('Description')}
              placeholder="Description"
              onChange={event => setDescription(event.target.value)}
              value={formData.values.description}
            />
            <FormErrorMessage errors={errors} wasChanged={wasChanged} property="description" prefix="values" />
          </Grid>
          <Grid item xs={12}>
            <Select label={getLabelForSelect('Owner *')} items={groups.value || []} onChange={setOwner} selected={formData.values.owner} />
          </Grid>
          <Grid item xs={12}>
            <Select label={getLabelForSelect('System *')} items={systems.value || []} onChange={setSystem} selected={formData.values.system} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Application Data</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} marginTop={2}>
            <Select
              label={getLabelForSelect('Application Run Template *')}
              items={templates?.value || []}
              onChange={createOnChangeHandler('templateRef')}
              selected={formData.templateRef}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={getLabelForSelect('Artifact *')}
              items={artifacts?.value || []}
              onChange={value => {
                setArtifactName(value);
                if (!formData.values.deploymentName) {
                  setDeploymentName(parseEntityRef(value as string).name);
                }
              }}
              selected={formData.values.artifactName}
            />
          </Grid>
          <Grid item xs={12}>
            <Select label={getLabelForSelect('ArgoCD Instance *')} items={instances?.value || []} onChange={setArgoCDInstance} selected={formData.values.argoCDInstance} />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={getLabelForSelect('ArgoCD Project *')}
              items={projects?.value || []}
              onChange={setArgoCDProject}
              selected={formData.values.argoCDProject}
              disabled={isInputDisabled(formData, 'argoCDProject')}
            />
            {projects?.loading && <Progress />}
          </Grid>
          <Grid item xs={12}>
            <Select
              label={getLabelForSelect('Cluster Name *')}
              items={clusters?.value || []}
              onChange={createOnChangeHandler('values.clusterName')}
              selected={formData.values.clusterName}
              disabled={isInputDisabled(formData, 'clusterName')}
            />
            {clusters?.loading && <Progress />}
          </Grid>
          <Grid item xs={12}>
            {renderNSInput()}
            {namespaceSettings?.loading && <Progress />}
            <FormErrorMessage errors={errors} wasChanged={wasChanged} property="namespace" prefix="values" />
          </Grid>
          <Grid item xs={12}>
            <RepoUrlPickerDropdown
              disabled={isInputDisabled(formData, 'repoUrl')}
              value={formData.values.repoUrl}
              onChange={setRepoUrl}
              items={repositories.value || []}
              label="ArgoCD Repository"
              getLabelForSelect={getLabelForSelect}
              uiSchema={UI_SCHEMA}
            />
            {repositories?.loading && <Progress />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
