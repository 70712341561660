import { Progress, Select, SelectItem } from '@backstage/core-components';
import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import _ from 'lodash';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { CustomFormLabel } from '../../CustomFormLabel';
import { AddClusterToControlPlaneModalProps } from './types';

const useStyles = makeStyles(
  theme => ({
    title: {
      width: '100%',
      color: theme.page.fontColor,
      backgroundImage: theme.page.backgroundImage,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
  })
);

export const AddClusterToControlPlaneModal = (props: AddClusterToControlPlaneModalProps) => {
  const { open, title = 'Add new', toggleModal, onSubmit, existingClustersList } = props;
  const [form, setForm] = useState<{ clusterName: string }>({
    clusterName: '',
  });
  const classes = useStyles();
  
  
  const [loading, setLoading] = useState(false);

  const catalogApi = useApi(catalogApiRef);
  const { value: entities } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({ filter: { kind: 'Resource', 'spec.type': 'k8s-cluster' } });
    return items;
  }, []);

  const dict = _.keyBy(existingClustersList, 'clusterName');
  const selectItems = _.reduce(
    entities,
    (acc: SelectItem[], item) => {
      if (!dict[item.metadata.name]) {
        acc.push({ label: item.metadata.name, value: item.metadata.name });
      }
      return acc;
    },
    [],
  );

  return (
    <Dialog onClose={toggleModal} fullWidth open={!!open}>
      {loading && <Progress />}
      <DialogTitle classes={classes.title}>{title}</DialogTitle>
      <DialogContent dividers>
        <Select
          label="Cluster Name"
          items={selectItems || []}
          onChange={value => setForm({ clusterName: _.toString(value) })}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={toggleModal} color="default" disabled={loading}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLoading(true);
            setForm({ clusterName: '' });
            onSubmit(form).then(() => {
              setLoading(false);
              toggleModal();
            });
          }}
          disabled={_.isEmpty(form.clusterName) || loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
