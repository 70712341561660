import {DiscoveryApi, FetchApi} from "@backstage/core-plugin-api";
import {ResponseError} from '@backstage/errors';
import crossFetch from "cross-fetch";
import {EntityManagementApi} from "./types/api";


export class EntityManagementClient implements EntityManagementApi {
  private discoveryApi: DiscoveryApi;
  private fetchApi: FetchApi;
  
  constructor(options: {discoveryApi: DiscoveryApi, fetchApi?: FetchApi}) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }
  
  async request<Type>(options: {url: string, pluginId?: string, method?: string, body: Type, token?: string}) {
    const apiOrigin = await this.discoveryApi.getBaseUrl(options?.pluginId ? options?.pluginId : 'entity-management');
    const requestUrl = `${apiOrigin}/${options.url}`;
    const response = await this.fetchApi.fetch(requestUrl, {
      method: options.method || 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: (options.body && options.method !== 'GET') ? options.body : null,
    });
    return response.ok ? response.json() : Promise.reject(await ResponseError.fromResponse(response));
  }
}