import { useEntity } from '@backstage/plugin-catalog-react';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { CardTMatic } from '../CardTMatic';
import React, { useState } from 'react';
import { RepositoryInfo } from '@tmatic/k8s-common';
import { TableColumn, Link } from '@backstage/core-components';
import { ConfirmDialog } from '../buttons/ConfirmActionButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { AddRepositoryToControlPlaneModal } from '../modals/AddRepositryToControlPlaneModal/AddRepositoryToControlPlaneModal';
import {entityManagementApiRef} from "../../apis";
import {ResourceEntity} from "@backstage/catalog-model";

const useStyles = makeStyles(() => ({ justifyContentEnd: { textAlign: 'right' } }));

const AddNewButton = (props: { entity: ResourceEntity; existingRepositoriesList: RepositoryInfo[]; setNow: (date: number) => void }) => {
  const { setNow, entity, existingRepositoriesList } = props;
  const [showModal, setShowModal] = useState(false);
  const entityManagementClient = useApi(entityManagementApiRef);
  
  const errorApi = useApi(errorApiRef);

  const onSubmit = async (propsOnSubmit: { repositoryUrl: string }) => {
    const { repositoryUrl } = propsOnSubmit;
    try {
      await entityManagementClient.request({
        method: 'POST',
        url: `${entity.metadata.namespace}/${entity.metadata.name}/repositories`,
        pluginId: 'k8s',
        body: JSON.stringify({ repositoryUrl }),
      });
      setNow(Date.now());
    } catch (error) {
      errorApi.post(error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
        Add New
      </Button>
      <AddRepositoryToControlPlaneModal
        existingRepositoriesList={existingRepositoriesList}
        title="Add New"
        open={showModal}
        toggleModal={() => setShowModal(!showModal)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export const ArgoCDRepositoriesCard = () => {
  const { entity} = useEntity<ResourceEntity>();
  const entityManagementClient = useApi(entityManagementApiRef);
  const errorApi = useApi(errorApiRef);
  const [now, setNow] = useState(Date.now());
  const [uid, setUid] = useState<string>('');
  const classes = useStyles();

  const columns: TableColumn<RepositoryInfo>[] = [
    { title: 'Repository Name', field: 'repositoryName' },
    { title: 'Repository Namespace', field: 'repositoryNamespace' },
    {
      title: 'Repository URL',
      render: rowData => {
        return !_.isString(rowData.repositoryUrl) || !_.size(rowData.repositoryUrl) ? null : <Link to={rowData.repositoryUrl}>{rowData.repositoryUrl}</Link>;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        const onConfirm = async () => {
          try {
            await entityManagementClient.request({
              method: 'DELETE',
              url: `${entity.metadata.namespace}/${entity.metadata.name}/repository/${rowData.repositoryName}`,
              pluginId: 'k8s'
            });
            setNow(Date.now());
          } catch (error) {
            errorApi.post(error);
          }
        };
        return !rowData.repositoryName ? null : <ConfirmDialog onConfirm={onConfirm} />;
      },
    },
  ];
  
  const isClusterReady = () => {
    const targetCondition = _.find(entity.spec.state?.conditions, condition => condition.type.toLocaleLowerCase('en-US') === 'ready');
    return targetCondition?.status.toLocaleLowerCase('en-US') === 'true';
  }

  const {
    value: secretRefs = [],
    loading,
    error,
  } = useAsync(async () => {
    if (!isClusterReady()) {
      return [];
    }
    return entityManagementClient.request({
      url: `${entity.metadata.namespace}/${entity.metadata.name}/repositories`,
      pluginId: 'k8s'
    });
  }, [entityManagementClient, now]);

  const repositoriesList: RepositoryInfo[] = _.map(secretRefs, secret => {
    return {
      repositoryName: secret.secretName, // remove the secret prefix acd-repo
      repositoryNamespace: secret.secretNamespace,
      repositoryUrl: secret.secretResourceUrl,
      secretOwnerName: secret.secretOwnerName,
    };
  });
  
  const emptyText = "Repositories not found";
  const cardTitle = 'Repositories';

  return (
    <Grid container spacing={3} direction="row">
      <Grid item xs={12} className={classes.justifyContentEnd}>
        <AddNewButton entity={entity} existingRepositoriesList={repositoriesList} setNow={setNow} />
      </Grid>
      <Grid item xs={12}>
        <CardTMatic<RepositoryInfo> data={repositoriesList} cardTitle={cardTitle} columns={columns} loading={loading} error={error} emptyText={emptyText} />
      </Grid>
    </Grid>
  );
};
