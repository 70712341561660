import {
  controlPlaneRouteRef,
} from './routes';
import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

/**
 * The Backstage plugin that renders technical documentation for your components
 *
 * @public
 */
export const k8sPlugin = createPlugin({
  id: 'k8s',
  apis: [
    // createApiFactory({
    //   api: techdocsApiRef,
    //   deps: {
    //     configApi: configApiRef,
    //     discoveryApi: discoveryApiRef,
    //     fetchApi: fetchApiRef,
    //   },
    //   factory: ({ configApi, discoveryApi, fetchApi }) =>
    //     new TechDocsClient({
    //       configApi,
    //       discoveryApi,
    //       fetchApi,
    //     }),
    // }),
  ],
  routes: {
    controlPlane: controlPlaneRouteRef
  },
});


/**
 * @public
 */
export const ControlPlanesIndexPage = k8sPlugin.provide(
  createRoutableExtension({
    name: 'TechDocsIndexPage',
    component: () =>
      import('./components/ControlPlaneExplorerPage').then(
        m => m.DefaultControlPlaneExplorerPage,
      ),
    mountPoint: controlPlaneRouteRef,
  }),
);