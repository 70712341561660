import axios from 'axios';
import _ from 'lodash';
import { CfgRecord, SerializeConfigRecordFn } from '../types';
import { createApiRef, DiscoveryApi, FetchApi} from "@backstage/core-plugin-api";
import {ResponseError} from "@backstage/errors";

export interface ConfiguratorApi {
  listSecrets(): Promise<any>;
  saveSecret<T extends Record<string, any>>(payload: T): Promise<any>;
  deleteSecret<T extends Record<string, any>>(payload: T): Promise<any>;
  listDiscoveries(props?: { serialize?: SerializeConfigRecordFn }): Promise<CfgRecord[]>;
  saveDiscovery(payload: CfgRecord): Promise<any>;
  deleteDiscovery(payload: CfgRecord): Promise<any>;
  listIntegrations(props?: { serialize?: SerializeConfigRecordFn }): Promise<CfgRecord[]>;
  saveIntegration(payload: CfgRecord): Promise<any>;
  deleteIntegration(payload: CfgRecord): Promise<any>;
  getConfig(): Promise<any>;
  getAppToken(): Promise<any>;
  setConfig(formData: any): Promise<any>;
  deleteConfig(formData: any): Promise<any>;
}

export const configuratorApiRef = createApiRef<ConfiguratorApi>({
  id: 'plugin.configurator.service',
});

export class ConfiguratorClient implements ConfiguratorApi {
  private discoveryApi: DiscoveryApi;
  private fetchApi: FetchApi;
  private backendUrl: string = '';
  
  constructor(options: {discoveryApi: DiscoveryApi, fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async listSecrets() {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/credentials`);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async saveSecret<T extends Record<string, any>>(payload: T) {
    const headers = { 'content-type': 'application/json' };
    return payload.uid
      ? axios.patch(`${this.backendUrl}/api/configurator/credentials/${payload.uid}`, JSON.stringify(_.omit(payload, ['uid'])), { headers })
      : axios.post(`${this.backendUrl}/api/configurator/credentials`, JSON.stringify(payload), { headers });
  }

  async deleteSecret<T extends Record<string, any>>(payload: T) {
    return axios.delete(`${this.backendUrl}/api/configurator/credentials/${payload.uid}`);
  }

  async listDiscoveries(props?: { serialize?: SerializeConfigRecordFn }): Promise<CfgRecord[] | never> {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/config/get`, {
      method: 'POST',
      body: JSON.stringify({ type: 'discovery' }),
      headers: { 'content-type': 'application/json' }
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const data = await response.json();
    return _.isFunction(props?.serialize) ? _.map(data, props!.serialize) : data;
  }

  async saveDiscovery(payload: CfgRecord) {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/config/set`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' }
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async deleteDiscovery(payload: CfgRecord) {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/config/unset`, {
      method: 'POST',
      body: JSON.stringify(_.omit(payload, ['type'])),
      headers: { 'content-type': 'application/json' }
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async listIntegrations(props?: { serialize?: SerializeConfigRecordFn }): Promise<CfgRecord[] | never> {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/config/get`, {
      method: 'POST',
      body: JSON.stringify({ type: 'integrations' }),
      headers: { 'content-type': 'application/json' }
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const data = await response.json();
    return _.isFunction(props?.serialize) ? _.map(data, props!.serialize) : data;
  }

  async saveIntegration(payload: CfgRecord) {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/config/set`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' }
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
    
  }

  async deleteIntegration(payload: CfgRecord) {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/config/unset`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' }
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async getConfig(): Promise<any> {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}`);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async getAppToken(): Promise<any> {
    const response = await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('configurator')}/token`);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async setConfig(formData: any): Promise<any> {
    const response = await this.fetchApi.fetch(`${this.backendUrl}/api/configurator`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async deleteConfig(formData: any): Promise<any> {
    const response = await this.fetchApi.fetch(`${this.backendUrl}/api/configurator`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }
}
