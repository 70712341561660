import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {configuratorApiRef, ConfiguratorClient} from "./service/ConfiguratorAPI";

export const configuratorPlugin = createPlugin({
  id: 'configurator',
  apis: [
    createApiFactory({
      api: configuratorApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new ConfiguratorClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
  
  routes: {
    root: rootRouteRef,
  },
});

export const ConfiguratorPage = configuratorPlugin.provide(
  createRoutableExtension({
    name: 'ConfiguratorPage',
    component: () => import('./pages/ConfiguratorPage').then(m => m.ConfiguratorPage),
    mountPoint: rootRouteRef,
  }),
);
