import { BackstageTheme } from '@backstage/theme';
import {
  makeStyles,
  createStyles,
  useTheme
} from "@mui/styles";

export const useStyles = makeStyles(() => {
  const theme = useTheme<BackstageTheme>();
  return createStyles({
    root: {
      width: '100%',
    },
    item: {
      display: 'flex',
      alignItems: 'flex-start',
      borderTop: '1px solid #ffffff1f',
      marginBottom: theme.spacing(1),
    },
    childrenHeader: {
      marginTop: theme.spacing(1),
    },
    childrenItem: {
      display: 'flex',
      width: '100%',
      '& .form-group': {
        width: '100%',
      },
    },
    childrenActions: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'row',
      '& label': {
        flex: '0 0 22%',
        margin: theme.spacing(1),
      },
      [theme.breakpoints.down('lg')]: {
        '& label': {
          flex: '0 0 45%',
          margin: theme.spacing(1),
        },
      },
    },
    search: {
      width: '80%',
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    selectedGroup: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.mode === 'dark' ? '#1C1C28' : '#CCDDFF',
      borderRadius: '8px',
    },
    cta: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
      minWidth: '15%',
    },
    actions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    //
    objectRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardHeader: {
      padding: theme.spacing(2),
    },
    cardContent: {
      paddingTop: 0,
    },
    objectItem: {
      marginTop: theme.spacing(2),
      width: '100%',
      '& .form-group': {
        width: '100%',
      },
      '&:first-child': {
        marginTop: 0,
      },
    },
    accordionDetails: {
      marginBottom: theme.spacing(2),
    },
  });
});
