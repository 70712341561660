import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { CardTMatic } from '../CardTMatic';
import React, { useState } from 'react';
import { ArgoCDInstanceMetadata, ClusterInfo, SecretRef } from '@tmatic/k8s-common';
import { TableColumn, Link } from '@backstage/core-components';
import { ConfirmDialog } from '../buttons/ConfirmActionButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { AddClusterToControlPlaneModal } from '../modals/AddClusterToControlPlaneModal/AddClusterToControlPlaneModal';
import { STATIC_SECRET_TYPE } from '@tmatic/entity-management-common';
import {entityManagementApiRef} from "../../apis";

const useStyles = makeStyles(() => ({ justifyContentEnd: { textAlign: 'right' } }));
const NAMESPACE = 'argocd';
const LABEL = STATIC_SECRET_TYPE.CLUSTER;

const AddNewButton = (props: { uid: string; existingClusterList: ClusterInfo[]; setNow: (date: number) => void }) => {
  const { setNow, uid, existingClusterList } = props;
  const [showModal, setShowModal] = useState(false);
  const entityManagementClient = useApi(entityManagementApiRef);
  const errorApi = useApi(errorApiRef);

  const onSubmit = async (propsOnSubmit: { clusterName: string }) => {
    try {
      await entityManagementClient.request({
        method: 'POST',
        url: `instances/${uid}/secrets/${LABEL}`,
        pluginId: 'argocd',
        body: JSON.stringify({ ...propsOnSubmit, namespace: NAMESPACE, label: LABEL }),
      });
      setNow(Date.now());
    } catch (error) {
      errorApi.post(error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
        Attach Cluster
      </Button>
      <AddClusterToControlPlaneModal existingClustersList={existingClusterList} title="Add New" open={showModal} toggleModal={() => setShowModal(!showModal)} onSubmit={onSubmit} />
    </>
  );
};

export const ArgoCDClustersCard = () => {
  const { entity } = useEntity();
  const entityManagementClient = useApi(entityManagementApiRef);
  const errorApi = useApi(errorApiRef);
  const cardTitle = 'Clusters';
  const [now, setNow] = useState(Date.now());
  const [uid, setUid] = useState<string>('');
  const classes = useStyles();

  const columns: TableColumn<ClusterInfo>[] = [
    { title: 'Cluster Name', field: 'clusterName' },
    { title: 'Cluster Namespace', field: 'clusterNamespace' },
    {
      title: 'Cluster URL',
      render: rowData => {
        return !_.isString(rowData.clusterUrl) || !_.size(rowData.clusterUrl) ? null : <Link to={rowData.clusterUrl}>{rowData.clusterUrl}</Link>;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        const onConfirm = async () => {
          try {
            await entityManagementClient.request({
              method: 'DELETE',
              pluginId: 'argocd',
              url: `instances/${uid}/namespace/${NAMESPACE}/secrets/${rowData.secretOwnerName}`
            });
            setNow(Date.now());
          } catch (error) {
            errorApi.post(error);
          }
        };
        return !rowData.secretOwnerName ? null : <ConfirmDialog onConfirm={onConfirm} />;
      },
    },
  ];

  const {
    value: secretRefs = [],
    loading,
    error,
  } = useAsync(async () => {
    const metadata = await entityManagementClient.request({
      method: 'GET',
      pluginId: 'argocd',
      url: `cluster/${entity.metadata.name}/metadata`
    });

    if (metadata?.uid) {
      setUid(metadata?.uid);
      return await entityManagementClient.request({
        method: 'GET',
        pluginId: 'argocd',
        url: `instances/${metadata?.uid}/label/${LABEL}/secrets`,
      });
    }
    return [];
  }, [entityManagementClient, now]);

  const clusterList: ClusterInfo[] = _.map(secretRefs, secret => {
    return {
      clusterName: secret.secretName.slice(4), // remove the secret prefix k8s-
      clusterNamespace: secret.secretNamespace,
      clusterUrl: secret.secretResourceUrl,
      secretOwnerName: secret.secretOwnerName,
    };
  });
  return (
    <Grid container spacing={3} direction="row">
      <Grid item xs={12} className={classes.justifyContentEnd}>
        <AddNewButton uid={uid} existingClusterList={clusterList} setNow={setNow} />
      </Grid>
      <Grid item xs={12}>
        <CardTMatic<ClusterInfo> data={clusterList} cardTitle={cardTitle} columns={columns} loading={loading} error={error} emptyText="No resources found" />
      </Grid>
    </Grid>
  );
};
