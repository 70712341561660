import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension, FieldExtensionOptions } from '@backstage/plugin-scaffolder-react';
import { ArgoCDResourcesReaderInput, DynamicContentPicker, dynamicContentPickerPickerValidation, DynamicContentPickerSchema } from './components';
import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { ArgoCDResourcesReaderInputSchema } from './components/fields/ArgoCDResourcesReader/schema';
import {entityManagementApiRef} from "./apis";
import { EntityManagementClient } from '@tmatic/entity-management-common';

export const entityManagementPlugin = createPlugin({
  id: 'entity-management',
  apis: [
    createApiFactory({
      api: entityManagementApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new EntityManagementClient({ discoveryApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef
  }
});

/**
 * DynamicContentPickerFieldExtension
 * @public
 */
export const DynamicContentPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: DynamicContentPicker,
    name: 'DynamicContentPicker',
    validation: dynamicContentPickerPickerValidation as FieldExtensionOptions['validation'],
    schema: DynamicContentPickerSchema,
  }),
);

/**
 * DynamicContentPickerFieldExtension
 * @public
 */
export const ArgoCDResourcesReaderFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: ArgoCDResourcesReaderInput,
    name: 'ArgoCDResourcesReader',
    // validation: dynamicContentPickerPickerValidation as FieldExtensionOptions['validation'],
    schema: ArgoCDResourcesReaderInputSchema,
  }),
);

/**
 * NpmPublicPackageInfoCard
 * @public
 */
export const NpmPublicPackageInfoCard: () => JSX.Element = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'NpmPublicPackageInfoCard',
    component: {
      lazy: () => import('./components/NpmPublicPackageInfoCard').then(m => m.NpmPublicPackageInfoCard),
    },
  }),
);

/**
 * DockerRepositoryInfoCard
 * @public
 */
export const DockerRepositoryInfoCard: () => JSX.Element = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'DockerRepositoryInfoCard',
    component: {
      lazy: () => import('./components/DockerRepositoryInfoCard').then(m => m.DockerRepositoryInfoCard),
    },
  }),
);

/**
 * AboutCardTMatic
 * @public
 */
export const AboutCardTMatic = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'AboutCardTMatic',
    component: {
      lazy: () => import('./components/AboutCardTMatic').then(m => m.AboutCardTMatic),
    },
  }),
);

export const ClaimParametersCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ClaimParametersCard',
    component: {
      lazy: () => import('./components/ClaimParametersCard').then(m => m.ClaimParametersCard),
    },
  }),
);

export const APIListPage = entityManagementPlugin.provide(
  createRoutableExtension({
    name: 'APIListPage',
    component: () => import('./pages/APIListPage').then(m => m.APIListPage),
    mountPoint: rootRouteRef,
  }),
);

export const ApplicationsListPage = entityManagementPlugin.provide(
  createRoutableExtension({
    name: 'ApplicationsListPage',
    component: () => import('./pages/ApplicationsListPage').then(m => m.ApplicationsListPage),
    mountPoint: rootRouteRef,
  }),
);

export const ArtifactsListPage = entityManagementPlugin.provide(
  createRoutableExtension({
    name: 'ArtifactsListPage',
    component: () => import('./pages/ArtifactsListPage').then(m => m.ArtifactsListPage),
    mountPoint: rootRouteRef,
  }),
);

export const ResourcesListPage = entityManagementPlugin.provide(
  createRoutableExtension({
    name: 'ResourcesListPage',
    component: () => import('./pages/ResourcesListPage').then(m => m.ResourcesListPage),
    mountPoint: rootRouteRef,
  }),
);

export const ComponentsListPage = entityManagementPlugin.provide(
  createRoutableExtension({
    name: 'ComponentListPage',
    component: () => import('./pages/ComponentsListPage').then(m => m.ComponentsListPage),
    mountPoint: rootRouteRef,
  }),
);

export const ResourceClusterStatusCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ResourceClusterStatusCard',
    component: { lazy: () => import('./components/ResourceClusterStatus').then(m => m.ResourceClusterStatus) },
  }),
);

export const ResourceClusterRelatedResourcesPage = entityManagementPlugin.provide(
  createRoutableExtension({
    name: 'ResourceClusterRelatedResourcesPage',
    component: () => import('./components/RelatedResourcesList').then(m => m.RelatedResourcesList),
    mountPoint: rootRouteRef,
  }),
);

export const ClaimDetailsCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ClaimDetailsCard',
    component: {
      lazy: () => import('./components/ClaimDetailsCard').then(m => m.ClaimDetailsCard),
    },
  }),
);

export const ApplicationArtifactCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ApplicationArtifactCard',
    component: {
      lazy: () => import('./components/ApplicationArtifactCard').then(m => m.ApplicationArtifactCard),
    },
  }),
);

export const ApplicationParametersCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ApplicationParametersCard',
    component: {
      lazy: () => import('./components/ApplicationParametersCard').then(m => m.ApplicationParametersCard),
    },
  }),
);

export const ProvidedApisCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ProvidedApisCard',
    component: {
      lazy: () => import('./components/ProvidedApisCard').then(m => m.ProvidedApisCard),
    },
  }),
);

export const ComponentArtifactCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ComponentArtifactCard',
    component: {
      lazy: () => import('./components/ComponentArtifactCard').then(m => m.ComponentArtifactCard),
    },
  }),
);

export const ParentComponentsCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'SubcomponentsCard',
    component: {
      lazy: () => import('./components/ParentComponentsCard').then(m => m.ParentComponentsCard),
    },
  }),
);

export const ComponentRepositoryCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ComponentRepositoryCard',
    component: {
      lazy: () => import('./components/ComponentRepositoryCard').then(m => m.ComponentRepositoryCard),
    },
  }),
);

export const HasApplicationCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'HasApplicationCard',
    component: {
      lazy: () => import('./components/HasApplicationCard').then(m => m.HasApplicationCard),
    },
  }),
);

export const DockerRepositoryInfoLatestCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'DockerRepositoryInfoLatestCard',
    component: {
      lazy: () => import('./components/DockerRepositoryInfoLatestCard').then(m => m.DockerRepositoryInfoLatestCard),
    },
  }),
);

export const HasComponentCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'HasComponentCard',
    component: {
      lazy: () => import('./components/HasComponentCard').then(m => m.HasComponentCard),
    },
  }),
);

export const ArgoCDClustersCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ArgoCDClustersCard',
    component: {
      lazy: () => import('./components/ArgoCDClustersCard').then(m => m.ArgoCDClustersCard),
    },
  }),
);

export const ArgoCDRepositoriesCard = entityManagementPlugin.provide(
  createComponentExtension({
    name: 'ArgoCDRepositoriesCard',
    component: {
      lazy: () => import('./components/ArgoCDRepositoriesCard').then(m => m.ArgoCDRepositoriesCard),
    },
  }),
);
