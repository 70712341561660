import { Progress } from '@backstage/core-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import _ from 'lodash';
import { AddRepositoriesToControlPlaneModalProps } from './types';

const useStyles = makeStyles(
  theme => ({
    title: {
      width: '100%',
      color: theme.page.fontColor,
      backgroundImage: theme.page.backgroundImage,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
  })
);

export const AddRepositoryToControlPlaneModal = (props: AddRepositoriesToControlPlaneModalProps) => {
  const { open, title = 'Add new', toggleModal, onSubmit } = props;
  const [form, setForm] = useState<{ repositoryUrl: string }>({
    repositoryUrl: '',
  });
  const classes = useStyles();
  
  const [loading, setLoading] = useState(false);

  return (
    <Dialog onClose={toggleModal} fullWidth open={!!open}>
      {loading && <Progress />}
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Repository URL"
          margin="normal"
          variant="outlined"
          fullWidth
          value={form.repositoryUrl}
          onChange={e => setForm({ repositoryUrl: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color="default" variant="outlined" onClick={toggleModal} disabled={loading}>Close</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLoading(true);
            setForm({ repositoryUrl: '' });
            onSubmit(form).then(() => {
              setLoading(false);
              toggleModal();
            });
          }}
          disabled={_.isEmpty(form.repositoryUrl) || loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
