import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { Progress, SelectItem } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { SecretsContextProvider, useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { RunApplicationPayload } from '@tmatic/plugin-argocd-common';
import { validate } from '@tmatic/configurator-common';
import { CreateApplicationPayload } from '@tmatic/entity-management-common';
import { urlToScaffolderLink } from '@tmatic/utils';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { useAsync } from 'react-use';
import _ from 'lodash';
import { CreateApplicationForm } from './CreateApplicationForm';
import Divider from '@mui/material/Divider';
import {entityManagementApiRef } from "../../../apis";

const INITIAL_STATE: CreateApplicationPayload = {
  templateRef: 'template:default/run-application',
  values: {
    deploymentName: '',
    description: '',
    owner: '',
    system: '',
    artifactName: '',
    argoCDInstance: '',
    argoCDProject: '',
    clusterName: '',
    namespace: '',
    repoUrl: 'github.com?repo=argocd',
  },
};

export interface CreateApplicationModalProps {
  open?: boolean;
  hidden?: boolean;
  toggleModal: () => void;
  onSubmit: (data: any) => Promise<void>;
  initialState?: typeof INITIAL_STATE;
}

const entitiesToSelectItems = (entities: Entity[]): SelectItem[] =>
  _.map(entities, entity => ({
    label: entity.metadata.name,
    value: stringifyEntityRef(entity),
  }));

const CreateApplicationDialog = (props: CreateApplicationModalProps) => {
  const { open, hidden, toggleModal, onSubmit, initialState = INITIAL_STATE } = props;
  const [form, setForm] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const { secrets } = useTemplateSecrets();

  const errorApi = useApi(errorApiRef);
  const catalogApi = useApi(catalogApiRef);
  const entityManagerClient = useApi(entityManagementApiRef);

  const templates = useAsync(async () => {
    try {
      const response = await catalogApi.getEntities({ filter: { kind: 'template', 'metadata.tags': 'run-app' } });
      return response.items
        .map(e => ({
          label: e.metadata.title || e.metadata.name,
          value: stringifyEntityRef(e),
        }))
        .sort();
    } catch (error) {
      errorApi.post(new Error('Error loading templates. Please, try again after refreshing page'));
      return [];
    }
  }, [catalogApi, errorApi]);

  const artifacts = useAsync(async () => {
    try {
      const response = await catalogApi.getEntities({ filter: { kind: 'artifact', 'spec.type': 'docker' } });
      return response.items
        .map(e => ({
          label: e.metadata.title || e.metadata.name,
          value: stringifyEntityRef(e),
        }))
        .sort();
    } catch (error) {
      errorApi.post(new Error('Error loading artifacts. Please, try again after refreshing page'));
      return [];
    }
  }, [catalogApi, errorApi]);

  const instances = useAsync(async () => {
    try {
      const { records } = await entityManagerClient.request({
        method: 'GET',
        url: '/instances',
        pluginId: 'argocd'
      });

      return records;
    } catch (error) {
      errorApi.post(new Error('Error loading available ArgoCD Instances. Please, try again after refreshing page'));
      return [];
    }
  }, [catalogApi, errorApi]);

  const { argoCDInstance, argoCDProject, clusterName } = form.values;
  const projects = useAsync(async () => {
    if (!argoCDInstance) {
      return [];
    }
    try {
      const { records } = await entityManagerClient.request({
        method: 'GET',
        url: `argocd/instances/${argoCDInstance}/projects`,
      });
      return records;
    } catch (error) {
      errorApi.post(new Error('Error loading available ArgoCD Projects. Please, try again after refreshing page'));
      return [];
    }
  }, [catalogApi, errorApi, argoCDInstance]);

  const clusters = useAsync(async () => {
    if (!argoCDInstance) {
      return [];
    }
    try {
      const { records } = await entityManagerClient.request({
        method: 'GET',
        url: `argocd/instances/${argoCDInstance}/clusters`,
      });
      return records;
    } catch (error) {
      errorApi.post(new Error('Error loading available clusters. Please, try again after refreshing page'));
      return [];
    }
  }, [catalogApi, errorApi, argoCDInstance]);

  const repositories = useAsync(async () => {
    if (!argoCDInstance) {
      return [];
    }
    try {
      const { records } = await entityManagerClient.request({
        method: 'GET',
        url: `argocd/instances/${argoCDInstance}/repositories`,
      });
      return records as SelectItem[];
    } catch (error) {
      errorApi.post(new Error('Error loading available clusters. Please, try again after refreshing page'));
      return [];
    }
  }, [catalogApi, errorApi, argoCDInstance]);

  const namespaceSettings = useAsync(async () => {
    if (!argoCDProject && !clusterName) {
      return { strategy: '*' };
    }
    try {
      const query: string[] = [];
      if (argoCDProject) {
        query.push(`project=${encodeURIComponent(argoCDProject)}`);
      }
      if (clusterName) {
        query.push(`cluster=${encodeURIComponent(clusterName)}`);
      }

      return await entityManagerClient.request({
        method: 'GET',
        url: `argocd/instances/${argoCDInstance}/namespace-settings?${query.join('&')}`
      });
    } catch (error) {
      errorApi.post(new Error('Error loading namespaces settings. Please, try again after refreshing page'));
      return { strategy: '*' };
    }
  }, [catalogApi, errorApi, argoCDProject, clusterName]);

  const groups = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'Group' } });
    return entitiesToSelectItems(result.items);
  }, []);

  const systems = useAsync(async () => {
    const result = await catalogApi.getEntities({ filter: { kind: 'System' } });
    return entitiesToSelectItems(result.items);
  }, []);

  const errors = validate(RunApplicationPayload, form);

  return (
    <Dialog style={{ padding: '2rem' }} onClose={toggleModal} aria-labelledby="search-modal-title" maxWidth="md" open={!!open} hidden={hidden}>
      {loading && <Progress />}
      <DialogTitle>Run Application with ArgoCD</DialogTitle>
      <Divider />
      <DialogContent>
        <CreateApplicationForm
          artifacts={artifacts}
          groups={groups}
          systems={systems}
          clusters={clusters}
          errors={errors}
          formData={form}
          instances={instances}
          namespaceSettings={namespaceSettings}
          projects={projects}
          repositories={repositories}
          setForm={setForm}
          templates={templates}
        />
      </DialogContent>
      <DialogActions style={{ padding: '0 2rem 2rem 2rem' }}>
        <Button variant="contained" onClick={toggleModal} color="secondary" disabled={loading}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (_.size(errors)) {
              // eslint-disable-next-line no-console
              console.error(errors);
            } else {
              setLoading(true);
              const payload = {
                ...form,
                values: { ...form.values, repoUrl: urlToScaffolderLink(form.values.repoUrl) },
                secrets,
              };
              onSubmit(payload).finally(() => setLoading(false));
            }
          }}
          disabled={loading || !!_.size(errors)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CreateApplicationModal = (props: CreateApplicationModalProps) => (
  <SecretsContextProvider>
    <CreateApplicationDialog {...props} />
  </SecretsContextProvider>
);
