import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Progress } from '@backstage/core-components';

interface ConfirmDialogProps {
  onConfirm: () => Promise<void>;
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { onConfirm } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    onConfirm().finally(() => {
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <div>
      {loading && <Progress />}
      <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>
        Delete
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="default" variant="outlined" disabled={loading}>
            No
          </Button>
          <Button onClick={onSubmit} color="primary" variant="contained" disabled={loading}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
