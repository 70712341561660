import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { ArgoCDResourcesReaderInputProps } from './schema';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { SelectItem } from '@backstage/core-components';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import { Select } from '@backstage/core-components';
import {entityManagementApiRef} from "../../../apis";

const getEndpoint = (resource: string, formData: Record<string, string>): string | null => {
  switch (resource) {
    case 'instances': {
      return 'argocd/instances';
    }
    case 'projects': {
      if (!_.size(formData?.argoCDInstance)) {
        return null;
      }
      return `argocd/instances/${formData.argoCDInstance}/projects`;
    }
    case 'clusters': {
      if (!_.size(formData?.argoCDInstance)) {
        return null;
      }
      return `argocd/instances/${formData.argoCDInstance}/clusters`;
    }
    case 'namespaces': {
      if (!_.size(formData?.argoCDInstance) || !_.size(formData?.clusterName)) {
        return null;
      }
      return `argocd/instances/${formData.argoCDInstance}/namespace-settings?cluster=${formData.clusterName}`;
    }
    default: {
      return null;
    }
  }
};

export const ArgoCDResourcesReaderInput = (props: ArgoCDResourcesReaderInputProps) => {
  const {
    name,
    onChange,
    required,
    rawErrors,
    formContext: { formData = {} } = {},
    schema: {
      title,
      // description,
    },
    uiSchema: { 'ui:autofocus': uiAutoFocus, 'ui:options': uiOptions = {} },
  } = props;

  const catalogClient = useApi(catalogApiRef);
  const entityManagementClient = useApi(entityManagementApiRef);
  const errorApi = useApi(errorApiRef);
  const [records, setRecords] = useState<SelectItem[]>([]);
  
  
  const endpoint = getEndpoint(uiOptions.resource as string, formData);
  useEffect(() => {
    if (_.isString(endpoint)) {
      const postError = (error: Error) => errorApi.post(error);
      if (uiOptions.resource === 'namespaces') {
        entityManagementClient.request<undefined, { records: string[] }>({
            method: 'GET',
            url: endpoint,
          })
          .then(response => setRecords(_.map(response.records, value => ({ value, label: value }))))
          .catch(postError);
      } else {
        entityManagementClient.request<undefined, { records: SelectItem[] }>({
            method: 'GET',
            url: endpoint,
          })
          .then(response => setRecords(response.records))
          .catch(postError);
      }
    }
  }, [catalogClient, errorApi, endpoint, uiOptions.resource]);

  const getInput = () => {
    if (!_.size(records) && uiOptions.useTextOnEmpty) {
      return (
        <TextField
          error={rawErrors?.length > 0 && !formData}
          inputProps={{ autoFocus: uiAutoFocus }}
          label={title}
          margin="normal"
          onChange={onChange}
          required={required}
          value={formData?.[name] || ''}
          variant="outlined"
        />
      );
    }

    return <Select disabled={!_.size(records)} label={title!} items={records} selected={formData?.[name] || ''} onChange={onChange} />;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>{getInput()}</FormControl>
      </Grid>
    </Grid>
  );
};
