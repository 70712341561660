import { Progress, Select } from '@backstage/core-components';
import { AddClusterPayload } from '@tmatic/configurator-common';
import MaterialButton from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import _ from 'lodash';
import { CreateClusterData, CreateClusterModalProps } from './types';
import { validateRequestPayload } from './utils';
import { useSpecRequestedSelection } from '../../../hooks/useSpecRequestedSelection';


export const CreateClusterModal = (props: CreateClusterModalProps) => {
  const { open, hidden, title = 'Add a new Cluster', toggleModal, onSubmit } = props;
  const [form, setForm] = useState<AddClusterPayload<CreateClusterData>>({
    description: '',
    owner: '',
    system: '',
    clusterData: {
      name: '',
    },
  });
  const { groups, systems } = useSpecRequestedSelection();
  const [loading, setLoading] = useState(false);
  
  const onCloseModal = () => {
    toggleModal();
    setForm({});
  }
  
  const onClickSubmit = () =>{
    setLoading(true);
    onSubmit(form).then(() => setLoading(false));
  }

  return (
    <Dialog style={{ padding: '2rem' }} onClose={onCloseModal} aria-labelledby="search-modal-title" maxWidth="md" open={!!open} hidden={hidden}>
      {loading && <Progress />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="Cluster Name"
          required
          margin="dense"
          variant="outlined"
          fullWidth
          value={form.clusterData?.name}
          onChange={e => setForm({ ...form, clusterData: { name: e.target.value } })}
        />
        <TextField
          label="Description"
          placeholder="Description"
          margin="dense"
          variant="outlined"
          fullWidth
          value={form.description}
          onChange={e => setForm({ ...form, description: e.target.value })}
        />
        <Select
          label="Owner"
          margin="dense"
          items={groups.value || []}
          onChange={value => setForm({ ...form, owner: _.toString(value) })}
        />
        <Select
          label="System"
          margin="dense"
          items={systems.value || []}
          onChange={value => setForm({ ...form, system: _.toString(value) })}
        />
      </DialogContent>
      <DialogActions style={{ padding: '0 2rem 2rem 2rem' }}>
        <MaterialButton color="default" variant="outlined"  onClick={onCloseModal}  disabled={loading}>Close</MaterialButton>
        <MaterialButton color="primary" variant="contained" onClick={onClickSubmit} disabled={!!_.size(validateRequestPayload(form)) || loading}>Submit</MaterialButton>
      </DialogActions>
    </Dialog>
  );
};
