export interface CreateApplicationPayload {
  templateRef: string;
  values: {
    deploymentName: string;
    description?: string;
    owner: string;
    system: string;
    artifactName: string;
    argoCDInstance: string;
    argoCDProject: string;
    clusterName: string;
    namespace: string;
    repoUrl: string;
  };
}

export interface CreateAPIPayload {
  name?: string;
  spec?: string;
  url?: string;
  description?: string;
  owner?: string;
  system?: string;
  version: '3.0' | '3.1';
  format: 'yaml';
}

export interface CreateArgoCDSecretClusterPayload {
  namespace: string;
  clusterName: string;
  label: string;
}

export interface CreateArgoCDSecretRepositoryPayload {
  namespace: string;
  label: string;
  repositoryUrl: string;
}

interface ArgoCDSecretType {
  CLUSTER: 'cluster';
  REPOSITORY: 'repository';
}

export const STATIC_SECRET_TYPE: ArgoCDSecretType = {
  CLUSTER: 'cluster',
  REPOSITORY: 'repository',
};

// export type { EntityManagementApi } from './api'